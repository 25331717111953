.mt__10 {
    margin-top: 10px;
    margin-bottom: 10px;
}
div#total__price {
    margin-top: 2rem;
}
#border-b{
	border-bottom: 1px solid !important;
	padding-bottom: 19px;
}
.popup__link:hover{
	color: #fff;
}